@import 'overrides/overrides';
@import '../../cremedelamer2_base/scss/cremedelamer2-base-mobile';
@import 'liveperson';
@import 'engraving_mobile';
@import '../../cremedelamer2_base/scss/sections/one_trust_cookie/cookie_settings_ot';
@import 'sign_in';
@import 'sections/checkout/checkout-viewcart';

.session-idle-popup__content {
  text-align: center;
}

.session-idle-popup__content--header {
  text-transform: uppercase;
  margin-bottom: 15px;
}

.session-idle-popup__content--desc {
  margin-bottom: 45px;
}

.site-header {
  &__utility-left {
    &.utilitynav-mobile {
      display: inline-block;
      position: absolute;
      right: 50px;
      top: 14px;
      .site-header__utility-item {
        display: none;
        &.lang-country-select {
          display: block;
        }
      }
    }
  }
  &__utility-item-nav {
    margin-left: 10px;
  }
  &__utility-right {
    margin-right: 20px;
    .gnav-util {
      &__icon {
        span {
          position: absolute;
          top: 4px;
          &.cart--bracket {
            display: none;
          }
        }
        &__cart-count {
          width: 23px;
        }
        .icon--bag {
          position: static;
          font-size: 14px;
          padding-left: 0;
          width: 23px;
          height: 23px;
          &::before {
            font-size: 23px;
          }
        }
      }
    }
  }
  &__utility-item {
    .lang--active,
    .spliter {
      font-weight: bold;
    }
  }
}

.site-header__menu {
  &__mobile-util-links {
    &--chat {
      top: -1px;
      position: relative;
      display: block;
    }
    &--login {
      float: left;
      margin-right: 14px;
    }
    &--email-signup,
    &--stores {
      float: left;
      margin-right: 14px;
    }
  }
}

.customer-service {
  &__agree-migrate {
    text-align: center;
    margin: 20px 0 20px 0;
  }
}

.cs-contact-info {
  &__description {
    line-height: 26px;
  }
  &__TEXT {
    .cs-contact-info {
      &__image {
        width: 19px;
      }
      &__title {
        margin-top: 12px;
      }
    }
  }
}

// mobile header util links
.site-header__menu__mobile-util-links {
  width: 100%;
  float: $ldirection;
  clear: both;
  a {
    float: $ldirection;
    margin-#{$rdirection}: 14px;
  }
}

.sticky__live-chat {
  &__mobile {
    position: fixed;
    bottom: 30%;
    right: 3px;
    text-align: center;
    z-index: 1;
    &--link {
      border: 1px solid $color-gray;
      border-radius: 50%;
      width: 25px;
      height: 25px;
      display: block;
      background: $color-white;
      img {
        vertical-align: top;
        margin-top: 4px;
      }
    }
  }
}

.site-footer {
  .menu-ref:nth-child(3) {
    display: block;
    margin: 0 auto;
    width: 87%;
  }
  .livechat__text {
    div:nth-child(3) {
      .text-beauty {
        float: right;
      }
    }
    margin-top: 5%;
    min-height: 42px;
    .text-beauty,
    .chat-beauty {
      @include breakpoint($med-small-only) {
        padding-top: 1%;
        line-height: 22px;
        font-size: 2.4vw;
      }
      border: 1px solid $color-sand;
      border-radius: 5px;
      color: $color-sand;
      background: $color-darkest-green;
      display: inline-flex;
      width: 48%;
      height: 55px;
      float: left;
      font-size: 2.8vw;
      line-height: 16px;
      font-weight: normal;
      text-transform: uppercase;
      padding: 3% 0 0 5%;
      &__text {
        display: inline-block;
        margin: 0 0 0 8%;
      }
      &__icon {
        margin-top: 5px;
        vertical-align: top;
        @include breakpoint($med-small-only) {
          margin-top: 12px;
        }
      }
    }
  }
}
