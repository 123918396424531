/* Engraving Styles Overlay */
.product-full__engraving-button {
  .engraving {
    display: inline-block;
    text-transform: none;
  }
  .monogramming {
    display: none;
  }
}

.engraving-info-icon {
  position: relative;
  #{$ldirection}: 6px;
}

.engraving-help-popup .engraving-popup-info {
  text-align: center;
  width: 100%;
  h1 {
    @include swap_direction(margin, 22px 8px 19px 0);
  }
  p {
    font-size: 20px;
    line-height: 22px;
    margin-bottom: 23px;
  }
  .engraving-details {
    li {
      list-style: none;
      list-style-position: inside;
      margin-bottom: 10px;
      font-size: 14px;
      line-height: 15px;
    }
  }
}

.engraving-button-container {
  display: block;
}

.engraving-preview-popup {
  #cboxContent {
    padding: 0;
  }
}

.spp-engraving {
  overflow: hidden;
  position: relative;
  .monogramming {
    display: none;
  }
  .error_messages_display {
    padding: 0;
    margin: 10px 0;
    font-size: 12px;
    color: $color-red;
    text-align: center;
  }
  &__engraving--forms {
    @include swap_direction(padding, 42px 0 25px 0);
    text-align: center;
  }
  &__engraving--form {
    text-align: $ldirection;
  }
  &__engraving--form-heading {
    width: 70%;
    margin: 0 auto;
    > h1 {
      font-size: 36px;
      line-height: 29px;
      margin-bottom: 13px;
      text-transform: uppercase;
      letter-spacing: 0.5px;
      text-align: center;
    }
    p {
      @include swap_direction(margin, 0 0 10px 0);
      font-family: $font--subheading;
      font-weight: bold;
      text-align: center;
      font-size: 18px;
    }
    .engraving-form-count {
      color: $color-green;
      text-transform: uppercase;
      font-size: 14px;
      font-family: $font--text;
      font-style: normal;
    }
  }
  &__engraving--form-font-choice-btn {
    display: none;
  }
  &__engraving--form-line {
    position: relative;
    margin-bottom: 11px;
    .char-counter {
      display: none;
    }
    .engraving-message {
      background: none;
      display: block;
      font-size: 16px;
      height: 50px;
      line-height: 32px;
      text-transform: none;
      box-shadow: none;
      width: 96%;
      text-align: center;
      padding: 0 10px;
    }
  }
  &__engraving--form-preview {
    visibility: hidden;
    height: 0;
    &.active {
      height: auto;
      visibility: visible;
    }
    .prod-img-wrap {
      height: 358px;
      width: 358px;
      margin: 0 auto;
      position: relative;
      text-align: center;
      overflow: hidden;
      img {
        margin: 0 auto;
        width: 100%;
      }
    }
    .engrave-placement {
      form.engraving-form-choice-31 &,
      form.engraving-form-choice-37 &,
      form.engraving-form-choice-38 &,
      form.engraving-form-choice-39 &,
      form.engraving-form-choice-35 & {
        position: absolute;
        top: -32px;
        #{$rdirection}: 0;
        bottom: 0;
        #{$ldirection}: 43px;
        width: 80px;
        height: 182px;
        -webkit-transform: rotate(90deg);
        -moz-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        -o-transform: rotate(90deg);
        transform: rotate(90deg);
      }
      .canvas-orig {
        background: none;
        border: none;
        position: relative;
        height: 20%;
        width: auto;
        color: $color-gold-light-engrave;
        font-size: 14px;
        letter-spacing: normal;
        white-space: nowrap;
        padding: 0;
        text-align: center;
        opacity: 0;
        &.engrave-choice-script {
          font-size: 16px;
        }
        &.preview-copy {
          opacity: 1;
          line-height: normal;
          &.engrave-choice-optima {
            font-family: $font--engrave;
            font-size: 13px;
            &.large-font {
              font-size: 8px;
            }
          }
        }
      }
      form.engraving-form-choice-31 & {
        top: 172px;
        #{$ldirection}: 91px;
        width: 99px;
        -webkit-transform: rotate(-38deg);
        -moz-transform: rotate(-38deg);
        -ms-transform: rotate(-38deg);
        -o-transform: rotate(-38deg);
        transform: rotate(-38deg);
      }
      form.engraving-form-choice-37 & {
        top: 170px;
        #{$ldirection}: 130px;
        width: 52px;
        -webkit-transform: rotate(-42deg);
        -moz-transform: rotate(-42deg);
        -ms-transform: rotate(-42deg);
        -o-transform: rotate(-42deg);
        transform: rotate(-42deg);
      }
      form.engraving-form-choice-38 & {
        top: 160px;
        #{$ldirection}: 132px;
        width: 52px;
        -webkit-transform: rotate(-38deg);
        -moz-transform: rotate(-38deg);
        -ms-transform: rotate(-38deg);
        -o-transform: rotate(-38deg);
        transform: rotate(-38deg);
      }
      form.engraving-form-choice-39 & {
        top: 148px;
        #{$ldirection}: 148px;
        width: 48px;
        -webkit-transform: rotate(-40deg);
        -moz-transform: rotate(-40deg);
        -ms-transform: rotate(-40deg);
        -o-transform: rotate(-40deg);
        transform: rotate(-40deg);
      }
      form.engraving-form-choice-35 & {
        top: 188px;
        #{$ldirection}: 122px;
        width: 80px;
        -webkit-transform: rotate(-22deg);
        -moz-transform: rotate(-22deg);
        -ms-transform: rotate(-22deg);
        -o-transform: rotate(-22deg);
        transform: rotate(-22deg);
        .canvas-orig {
          color: $color-gold-light-engrave;
        }
      }
    }
  }
  &__engraving--form-element-subtotaling {
    li {
      display: flex;
      .name {
        width: 85%;
      }
    }
  }
  &__engraving--form-element-footer {
    .engraving-msg,
    .engraving-cancel {
      display: none;
    }
  }
  .engraving-save {
    display: none;
  }
  .engraving-save,
  .engraving-preview {
    @include swap_direction(margin, 5px 0 23px 0);
    height: 50px;
    width: 96%;
    font-size: 12px;
    letter-spacing: 0.1em;
    line-height: 1;
  }
  .back-preview {
    text-transform: uppercase;
    text-align: center;
    display: block;
    margin-bottom: 20px;
  }
}

.engraving-info {
  text-align: center;
  margin-top: 10px;
  li {
    list-style: none;
    list-style-position: inside;
    margin-bottom: 15px;
    font-size: 13px;
    line-height: 15px;
  }
}

.engraving-preview-info {
  font-size: 13px;
  text-align: center;
  line-height: 18px;
}
/* View cart page - Engraving */
.engraving-wrapper {
  background: $color-gray-cs-side;
  padding: 20px 10px;
}

.engraving-view {
  &__name {
    text-align: center;
    margin-bottom: 18px;
  }
  &__price {
    float: $rdirection;
  }
  &__message {
    background: $color-gray-cs-side;
    &-content {
      font-family: $font--engrave;
      background: $color-white;
      padding: 15px;
      border-radius: 5px;
      border: 1px solid $color-light-gray;
      text-align: center;
      margin-bottom: 16px;
      word-break: break-word;
    }
    &-edit {
      margin-#{$ldirection}: 20%;
    }
    &-delete {
      margin-#{$ldirection}: 32.5%;
    }
    &-disclaimer {
      font-size: 80%;
      margin: 17px 0;
      line-height: 1.4;
    }
  }
}

.cart-items__engraving-sku-item {
  .cart-item__qty-replenishment-group {
    display: none;
  }
}
/* End of Engraving Styles */
/* Viewcart Page */
#viewcart {
  .cart-items {
    &__engraving-sku-item {
      border: none;
      padding: 0;
    }
  }
}
/* End of Viewcart Page */
/* GNAV */
.gnav-util {
  .cart-item {
    &__engraving {
      border-top: solid 1px $color-light-gray;
      border-bottom: 0;
      margin-#{$ldirection}: 16.5%;
      padding: 10px 0 0;
      &-detail {
        margin-bottom: 5px;
        font-family: $font--heading;
        font-size: 14px;
      }
      &-preview {
        background: none;
        padding: 0;
        margin-bottom: 20px;
        word-break: break-word;
        font-family: $font--engrave;
      }
    }
  }
}

.cart-confirm__engrave-container {
  display: none;
}
/* End of GNAV */

.checkout__sidebar {
  .cart-items {
    &__engraving-sku-item {
      .cart-item {
        border: none;
        padding-top: 0;
      }
    }
  }
  .engraving-button-container {
    clear: both;
    width: 76%;
    float: $rdirection;
    margin-bottom: 10px;
    display: block;
  }
}

.engraving-product-message {
  .engraved__text {
    font-family: $font--engrave;
    text-transform: none;
    font-weight: normal;
  }
}
